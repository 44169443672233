const Footer = () => {
  return (
    <div className='bg-primary'>
      <div className='container flex flex-col justify-between py-6 sm:flex-row'>
        <p className='text-center font-body text-white md:text-left'>
          © All Rights Reserved. Ana Goldman
        </p>
        <div className='flex items-center justify-center pt-5 sm:justify-start sm:pt-0'>
          <a
            href='https://www.linkedin.com/in/anastasia-goldman-38986a140/'
            target='_blank'
            rel='noreferrer'
            className='md:pl-4'
          >
            <i className='bx bxl-linkedin text-2xl text-white hover:text-yellow'></i>
          </a>
          <a
            href='https://github.com/ana-goldman'
            className='pl-4'
            target='_blank'
            rel='noreferrer'
          >
            <i className='bx bxl-github text-2xl text-white hover:text-yellow'></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
