const WorkExperience = () => {
  return (
    <div className='container py-16 md:py-20' id='work'>
      <h2 className='text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl'>
        My work experience
      </h2>
      <h3 className='pt-6 text-center font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl'>
        Here's what I've done before
      </h3>

      <div className='relative mx-auto mt-12 flex w-full flex-col lg:w-2/3'>
        <span className='left-2/5 absolute inset-y-0 ml-10 hidden w-0.5 bg-grey-40 md:block'></span>

        <div className='mt-8 flex flex-col text-center md:flex-row md:text-left'>
          <div className='md:w-2/5'>
            <div className='flex justify-center md:justify-start'>
              <span className='shrink-0'>
                <img src='/images/logo-amz.svg' className='h-auto w-32' alt='company logo' />
              </span>
              <div className='relative ml-3 hidden w-full md:block'>
                <span className='absolute inset-x-0 top-1/2 h-0.5 -translate-y-1/2 transform bg-grey-70'></span>
              </div>
            </div>
          </div>
          <div className='md:w-3/5'>
            <div className='relative flex md:pl-18'>
              <span className='absolute left-8 top-1 hidden h-4 w-4 rounded-full border-2 border-grey-40 bg-white md:block'></span>

              <div className='mt-1 flex'>
                <i className='bx bxs-right-arrow hidden text-primary md:block'></i>
                <div className='md:-mt-1 md:pl-8'>
                  <span className='block font-body font-bold text-grey-40'>
                    Jun 2023 - Jun 2024
                  </span>
                  <span className='block pt-2 font-header text-xl font-bold uppercase text-primary'>
                    Software Engineer
                  </span>
                  <div className='pt-2'>
                    <span className='block font-body text-black'>
                      Worked in a team on an SSG app based on Astro, React, TailwindCSS, integrated
                      with Sanity CMS.
                      <ul className='list-disc text-left list-inside md:list-outside'>
                        <li className='md:ml-8'>
                          Worked in Agile methodology including stand ups, sprints, CI/CD and code
                          reviews
                        </li>
                        <li className='md:ml-8'>Engaged in pair programming</li>
                        <li className='md:ml-8'>
                          Collaborated in code transition from React to Svelte
                        </li>
                        <li className='md:ml-8'>
                          Implemented internationalization and localization features
                        </li>
                        <li className='md:ml-8'>Integrated Google Tag Manager</li>
                        <li className='md:ml-8'>
                          Addressed bugs and improved code readability and performance
                        </li>
                        <li className='md:ml-8'>
                          Optimized application performance through Lighthouse metrics
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-8 flex flex-col text-center md:flex-row md:text-left'>
          <div className='md:w-2/5'>
            <div className='flex justify-center md:justify-start'>
              <span className='shrink-0'>
                <img src='/images/logo-bekker.svg' className='h-auto w-32' alt='company logo' />
              </span>
              <div className='relative ml-3 hidden w-full md:block'>
                <span className='absolute inset-x-0 top-1/2 h-0.5 -translate-y-1/2 transform bg-grey-70'></span>
              </div>
            </div>
          </div>
          <div className='md:w-3/5'>
            <div className='relative flex md:pl-18'>
              <span className='absolute left-8 top-1 hidden h-4 w-4 rounded-full border-2 border-grey-40 bg-white md:block'></span>

              <div className='mt-1 flex'>
                <i className='bx bxs-right-arrow hidden text-primary md:block'></i>
                <div className='md:-mt-1 md:pl-8'>
                  <span className='block font-body font-bold text-grey-40'>
                    Jun 2022 - Mar 2023
                  </span>
                  <span className='block pt-2 font-header text-xl font-bold uppercase text-primary'>
                    Frontend Developer
                  </span>
                  <div className='pt-2'>
                    <span className='block font-body text-black'>
                      Worked on creating and maintaining a coworking website based on React,
                      TypeScript, PHP, MySQL, Composer.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-8 flex flex-col text-center md:flex-row md:text-left'>
          <div className='md:w-2/5'>
            <div className='flex justify-center md:justify-start'>
              <span className='shrink-0'>
                <img src='/images/logo-jtb.svg' className='h-auto w-32' alt='company logo' />
              </span>
              <div className='relative ml-3 hidden w-full md:block'>
                <span className='absolute inset-x-0 top-1/2 h-0.5 -translate-y-1/2 transform bg-grey-70'></span>
              </div>
            </div>
          </div>
          <div className='md:w-3/5'>
            <div className='relative flex md:pl-18'>
              <span className='absolute left-8 top-1 hidden h-4 w-4 rounded-full border-2 border-grey-40 bg-white md:block'></span>

              <div className='mt-1 flex'>
                <i className='bx bxs-right-arrow hidden text-primary md:block'></i>
                <div className='md:-mt-1 md:pl-8'>
                  <span className='block font-body font-bold text-grey-40'>
                    Apr 2017 - Aug 2020
                  </span>
                  <span className='block pt-2 font-header text-xl font-bold uppercase text-primary'>
                    Credit Controller
                  </span>
                  <div className='pt-2'>
                    <span className='block font-body text-black'>
                      Working in the Finance Department of a Tour Agency managing payments with
                      suppliers, utilising the Microsoft Dynamics ERP software. This involved:
                      <ul className='list-disc text-left list-inside md:list-outside'>
                        <li className='md:ml-8'>Invoice and Payment processing</li>
                        <li className='md:ml-8'>
                          Financial Compliance, Reporting and Database Entry
                        </li>
                        <li className='md:ml-8'>Scheduling Payments</li>
                        <li className='md:ml-8'>Solving disputes and enquiries</li>
                        <li className='md:ml-8'>Working closely with the operations department</li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
