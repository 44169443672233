import React from 'react';
import './App.css';
import 'boxicons/css/boxicons.min.css';
import Header from './components/Header';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Skills from './components/Skills';
import WorkExperience from './components/WorkExperience';
import Footer from './components/Footer';
import Contact from './components/Contact';

function App() {
  return (
    <div className='App'>
      <div className='relative'>
        <Header />
        <About />
        <Portfolio />
        <Skills />
        <WorkExperience />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
