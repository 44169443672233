const Contact = () => {
  return (
    <div className='container py-16 md:py-20' id='contact'>
      <h2 className='text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl'>
        Here's how to contact me
      </h2>
      <h4 className='pt-6 text-center font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl'>
        Have Any Questions?
      </h4>
      <div className='mx-auto w-full pt-5 text-center sm:w-2/3 lg:pt-6'>
        <p className='font-body text-grey-10'>
          I'm always open to discussing new projects, creative ideas, or opportunities to be part of
          your visions. Feel free to reach out!
        </p>
      </div>

      <div className='flex flex-col pt-16 lg:flex-row lg:justify-center'>
        <div className='w-full border-l-2 border-t-2 border-r-2 border-b-2 border-grey-60 px-6 py-6 sm:py-8 lg:w-1/3'>
          <div className='flex items-center'>
            <i className='bx bx-phone text-2xl text-grey-40'></i>
            <p className='pl-2 font-body font-bold uppercase text-grey-40 lg:text-lg'>My Phone</p>
          </div>
          <p className='pt-2 text-left font-body font-bold text-primary lg:text-lg'>
            <a href='tel:+447442422111' className='hover:text-yellow'>
              (+44) 74 4242 2111
            </a>
          </p>
        </div>
        <div className='w-full border-l-2 border-t-0 border-r-2 border-b-2 border-grey-60 px-6 py-6 sm:py-8 lg:w-1/3 lg:border-l-0 lg:border-t-2'>
          <div className='flex items-center'>
            <i className='bx bx-envelope text-2xl text-grey-40'></i>
            <p className='pl-2 font-body font-bold uppercase text-grey-40 lg:text-lg'>My Email</p>
          </div>
          <p className='pt-2 text-left font-body font-bold text-primary lg:text-lg'>
            <a href='mailto:ms.anagoldman@gmail.com' className='hover:text-yellow'>
              ms.anagoldman@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
