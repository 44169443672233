const About = () => {
  return (
    <div className='bg-grey-50' id='about'>
      <div className='container flex flex-col items-center py-16 md:py-20 lg:flex-row'>
        <div className='w-full text-center sm:w-3/4 lg:w-3/5 lg:text-left'>
          <h2 className='font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl'>
            Who am I?
          </h2>
          <h4 className='pt-6 font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl'>
            I'm Ana, a Software Developer
          </h4>
          <p className='pt-6 font-body leading-relaxed text-grey-20'>
            Over the years, I've gathered a wealth of experience from studying, working, and
            traveling across the globe, enriching my perspective and fueling my creativity. For a
            while now, I've been focused on front-end development, blending my passion for creating
            user-friendly web applications with a keen eye for detail. I'm always looking for new
            challenges to grow both personally and professionally. In my free time, I enjoy film
            photography, capturing moments that inspire me.
          </p>
          <div className='flex flex-col justify-center pt-6 sm:flex-row lg:justify-start'>
            <div className='flex items-center justify-center sm:justify-start'>
              <p className='font-body text-lg font-semibold uppercase text-grey-20'>
                Connect with me
              </p>
              <div className='hidden sm:block'>
                <i className='bx bx-chevron-right text-2xl text-primary'></i>
              </div>
            </div>
            <div className='flex items-center justify-center pt-5 pl-2 sm:justify-start sm:pt-0'>
              <a
                href='https://github.com/ana-goldman'
                target='_blank'
                rel='noreferrer'
                className='md:pl-4'
              >
                <i className='bx bxl-github text-2xl text-primary hover:text-yellow'></i>
              </a>
              <a
                href='https://www.linkedin.com/in/anastasia-goldman-38986a140/'
                target='_blank'
                rel='noreferrer'
                className='pl-4'
              >
                <i className='bx bxl-linkedin text-2xl text-primary hover:text-yellow'></i>
              </a>
              <a href='mailto:ms.anagoldman@gmail.com' className='pl-4'>
                <i className='bx bxl-gmail text-2xl text-primary hover:text-yellow'></i>
              </a>
            </div>
          </div>
        </div>
        <div className='w-full pl-0 pt-10 sm:w-3/4 lg:w-2/5 lg:pl-12 lg:pt-0'>
          <div>
            <div className='flex items-end justify-between'>
              <h4 className='font-body font-semibold uppercase text-black'>TypeScript</h4>
              {/* <h3 className='font-body text-3xl font-bold text-primary'>90%</h3> */}
            </div>
            <div className='mt-2 h-3 w-full rounded-full bg-lila'>
              <div className='h-3 rounded-full bg-primary' style={{ width: '90%' }}></div>
            </div>
          </div>
          <div className='pt-6'>
            <div className='flex items-end justify-between'>
              <h4 className='font-body font-semibold uppercase text-black'>React</h4>
              {/* <h3 className='font-body text-3xl font-bold text-primary'>95%</h3> */}
            </div>
            <div className='mt-2 h-3 w-full rounded-full bg-lila'>
              <div className='h-3 rounded-full bg-primary' style={{ width: '95%' }}></div>
            </div>
          </div>
          <div className='pt-6'>
            <div className='flex items-end justify-between'>
              <h4 className='font-body font-semibold uppercase text-black'>Tailwind CSS</h4>
              {/* <h3 className='font-body text-3xl font-bold text-primary'>75%</h3> */}
            </div>
            <div className='mt-2 h-3 w-full rounded-full bg-lila'>
              <div className='h-3 rounded-full bg-primary' style={{ width: '75%' }}></div>
            </div>
          </div>
          <div className='pt-6'>
            <div className='flex items-end justify-between'>
              <h4 className='font-body font-semibold uppercase text-black'>Git</h4>
              {/* <h3 className='font-body text-3xl font-bold text-primary'>85%</h3> */}
            </div>
            <div className='mt-2 h-3 w-full rounded-full bg-lila'>
              <div className='h-3 rounded-full bg-primary' style={{ width: '85%' }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
