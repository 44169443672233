const Skills = () => {
  return (
    <div className='bg-grey-50' id='skills'>
      <div className='container py-16 md:py-20'>
        <div className='mx-auto w-full sm:w-3/4 lg:w-full'>
          <h2 className='text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl'>
            I'm skilled in
          </h2>
          <div className='flex flex-wrap items-center justify-center pt-4 sm:pt-4'>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-html5 text-7xl hover:text-yellow'></i>
              <span>HTML 5</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-css3 text-7xl hover:text-yellow'></i>
              <span>CSS 3</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-javascript text-7xl hover:text-yellow'></i>
              <span>JavaScript</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-tailwind-css text-7xl hover:text-yellow'></i>
              <span>Tailwind</span>
            </span>
            <span className='m-4  flex flex-col'>
              <i className='bx bxl-typescript text-7xl hover:text-yellow'></i>
              <span> TypeScript</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-react text-7xl hover:text-yellow'></i>
              <span>React</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-redux text-7xl hover:text-yellow'></i>
              <span>Redux</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-aws text-7xl hover:text-yellow'></i>
              <span>AWS</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-docker text-7xl hover:text-yellow'></i>
              <span>Docker</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-figma text-7xl hover:text-yellow'></i>
              <span>Figma</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-netlify text-7xl hover:text-yellow'></i>
              <span>Netlify</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-heroku text-7xl hover:text-yellow'></i>
              <span>Heroku</span>
            </span>
            <span className='m-4 flex flex-col'>
              <i className='bx bxl-git text-7xl hover:text-yellow'></i>
              <span>Git</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
