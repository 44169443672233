const Portfolio = () => {
  return (
    <div className='container py-16 md:py-20' id='portfolio'>
      <h2 className='text-center font-header text-4xl font-semibold uppercase text-primary sm:text-5xl lg:text-6xl'>
        Check out my Portfolio
      </h2>
      <h3 className='pt-6 text-center font-header text-xl font-medium text-black sm:text-2xl lg:text-3xl'>
        Here's what I have done in the past
      </h3>

      <div className='mx-auto grid w-full grid-cols-1 gap-8 pt-12 sm:w-3/4 md:gap-10 lg:w-full lg:grid-cols-2'>
        <a
          href='https://github.com/ana-goldman/messenger'
          target='_blank'
          className='mx-auto transform transition-all hover:scale-105 md:mx-0'
          rel='noreferrer'
        >
          <img
            src='/images/portfolio-chat.png'
            className='w-full shadow'
            alt='portfolio messenger'
          />
        </a>
        <a
          href='https://github.com/ana-goldman/snake-game'
          target='_blank'
          className='mx-auto transform transition-all hover:scale-105 md:mx-0'
          rel='noreferrer'
        >
          <img
            src='/images/portfolio-game.png'
            className='w-full shadow'
            alt='portfolio snake game'
          />
        </a>
        <a
          href='https://bekker-place.ru'
          target='_blank'
          className='mx-auto transform transition-all hover:scale-105 md:mx-0'
          rel='noreferrer'
        >
          <img
            src='/images/portfolio-coworking.png'
            className='w-full shadow'
            alt='portfolio coworking'
          />
        </a>
      </div>
    </div>
  );
};

export default Portfolio;
