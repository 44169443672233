const Menu = ({ openMenu }: { openMenu: () => void }) => {
  return (
    <div className='w-full z-50 top-0 py-3 sm:py-5 sticky bg-primary bg-gradient-to-r from-hero-gradient-from to-hero-gradient-to  md:absolute md:bg-none md:bg-transparent'>
      <div className='container flex justify-end '>
        <div className='hidden lg:block'>
          <ul className='flex items-center'>
            <li className='group pl-6'>
              <a
                href='#about'
                className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
              >
                About
              </a>
              <span className='block h-0.5 w-full bg-transparent group-hover:bg-yellow'></span>
            </li>

            <li className='group pl-6'>
              <a
                href='#portfolio'
                className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
              >
                Portfolio
              </a>
              <span className='block h-0.5 w-full bg-transparent group-hover:bg-yellow'></span>
            </li>

            <li className='group pl-6'>
              <a
                href='#skills'
                className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
              >
                Skills
              </a>
              <span className='block h-0.5 w-full bg-transparent group-hover:bg-yellow'></span>
            </li>

            <li className='group pl-6'>
              <a
                href='#work'
                className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
              >
                Work
              </a>
              <span className='block h-0.5 w-full bg-transparent group-hover:bg-yellow'></span>
            </li>

            <li className='group pl-6'>
              <a
                href='#contact'
                className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
              >
                Contact
              </a>
              <span className='block h-0.5 w-full bg-transparent group-hover:bg-yellow'></span>
            </li>
          </ul>
        </div>
        <div className='block lg:hidden'>
          <button onClick={() => openMenu()}>
            <i className='bx bx-menu text-4xl text-white'></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Menu;
