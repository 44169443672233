const MobileMenu = ({ open, closeMenu }: { open: boolean; closeMenu: () => void }) => {
  return (
    <div
      className={`pointer-events-none fixed inset-0 z-70 min-h-screen bg-black bg-opacity-70 transition-opacity lg:hidden ${
        open ? 'opacity-100 pointer-events-auto' : 'opacity-0'
      }`}
    >
      <div className='absolute right-0 min-h-screen w-2/3 bg-primary py-4 px-8 shadow md:w-1/3'>
        <button className='absolute top-0 right-0 mt-4 mr-4' onClick={closeMenu}>
          <img src='/images/icon-close.svg' className='h-10 w-auto' alt='close mobile menu' />
        </button>

        <ul className='mt-8 flex flex-col'>
          <li className='py-2'>
            <a
              href='#about'
              onClick={closeMenu}
              className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
            >
              About
            </a>
          </li>

          <li className='py-2'>
            <a
              href='#portfolio'
              onClick={closeMenu}
              className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
            >
              Portfolio
            </a>
          </li>

          <li className='py-2'>
            <a
              href='#skills'
              onClick={closeMenu}
              className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
            >
              Skills
            </a>
          </li>

          <li className='py-2'>
            <a
              href='#work'
              onClick={closeMenu}
              className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
            >
              Work
            </a>
          </li>

          <li className='py-2'>
            <a
              href='#contact'
              onClick={closeMenu}
              className='cursor-pointer pt-0.5 font-header font-semibold uppercase text-white'
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
